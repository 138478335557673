import React, { useState, useEffect, useContext, useCallback, useMemo }   from 'react';
import                                                   './CampaignCreative.css';
import { AuthContext }                              from '../../contexts/authContext';
import axios                                        from 'axios';
import NavigateNextIcon                             from '@mui/icons-material/NavigateNext';
import { FormattedMessage, injectIntl }             from 'react-intl';
import moment                                       from "moment";
import CommonUtilities                              from "../../util/CommonUtilities";
import LegalLinks                                   from '../../components/LegalLinks';
import { useHistory }                               from 'react-router-dom'
import config                                       from '../../config';
import { NotificationManager }                      from 'react-notifications';
import { v4 as uuidv4 }                             from 'uuid';
import DialogActions                                from '@mui/material/DialogActions';
import Dialog                                       from '@mui/material/Dialog';
import DialogTitle                                  from '@mui/material/DialogTitle';
import WarningAmberIcon                             from '@mui/icons-material/WarningAmber';
import {useDropzone}                                from 'react-dropzone';
import { Image24, Close32 }                         from '@carbon/icons-react';
import CircleProgress                               from '../../util/CircleProgress';
import IconButton                                   from '@mui/material/IconButton';

const baseStyle = {
    //flex: 1,
    width: '250px',
    height: '400px',
    marginLeft: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //padding: '50px',
    borderWidth: 3,
    borderRadius: 5,
    borderColor: '#aaaaaa',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
  
const focusedStyle = {
    borderColor: '#2196f3'
};
  
const acceptStyle = {
    borderColor: '#00e676'
};
  
const rejectStyle = {
    borderColor: '#ff1744'
};

const CampaignCreative = (props, { dispatch }) =>{
    const [ loading              ,setLoading                ] = useState(true);
    const [ accessToken          ,setAccessToken            ] = useState('');
    const [ refreshToken         ,setRefreshToken           ] = useState('');
    const [ country              ,setCountry                ] = useState(localStorage.getItem('country'));
    //state params:
    //vengono inizializzati dal localStorage se !== null (in quanto si potrebbe arrivare da altre pagine con dei parametri) o con un valore di default
    const [ kCampaign            ,setKCampaign              ] = useState(localStorage.getItem('kCampaign')            !== null ? localStorage.getItem('kCampaign') : undefined);
    const [ campaignName         ,setCampaignName           ] = useState(localStorage.getItem('campaignName')         !== null ? localStorage.getItem('campaignName') : undefined);
    const [ floor                ,setFloor                  ] = useState(localStorage.getItem('floor')                !== null ? CommonUtilities.stringToInt(localStorage.getItem('floor')) : undefined);
    const [ giant                ,setGiant                  ] = useState(localStorage.getItem('giant')                !== null ? CommonUtilities.stringToBoolean(localStorage.getItem('giant')) : undefined);
    const [ allFrames            ,setAllFrames              ] = useState(localStorage.getItem('allFrames')            !== null ? CommonUtilities.stringToBoolean(localStorage.getItem('allFrames')) : undefined);
    const [ daypart              ,setDaypart                ] = useState(localStorage.getItem('daypart')              !== null ? parseInt(localStorage.getItem('daypart')) : undefined);
    const [ beginDate            ,setBeginDate              ] = useState(localStorage.getItem('beginDate')            !== null ? moment(localStorage.getItem('beginDate')) : undefined);
    const [ endDate              ,setEndDate                ] = useState(localStorage.getItem('endDate')              !== null ? moment(localStorage.getItem('endDate')) : undefined);
    const [ gloohCreativeId      ,setGloohCreativeId        ] = useState(localStorage.getItem('gloohCreativeId')      !== null ? localStorage.getItem('gloohCreativeId') : '');
    const [ gloohBriefId         ,setGloohBriefId           ] = useState(localStorage.getItem('gloohBriefId')         !== null ? localStorage.getItem('gloohBriefId') : '');
    const [ gloohId              ,setGloohId                ] = useState(localStorage.getItem('gloohId')              !== null ? localStorage.getItem('gloohId') : '');
    const [ gloohPwd             ,setGloohPwd               ] = useState(localStorage.getItem('gloohPwd')             !== null ? localStorage.getItem('gloohPwd') : '');
    const [ gloohRendered        ,setGloohRendered          ] = useState(localStorage.getItem('gloohRendered')        !== null ? CommonUtilities.stringToBoolean(localStorage.getItem('gloohRendered')) : false);
    const [ priceMedia           ,setPriceMedia             ] = useState(localStorage.getItem('priceMedia')           !== null ? parseFloat(localStorage.getItem('priceMedia')) : undefined);
    const [ priceAdv             ,setPriceAdv               ] = useState(localStorage.getItem('priceAdv')             !== null ? parseFloat(localStorage.getItem('priceAdv')) : undefined);    
    const [ priceTva             ,setPriceTva               ] = useState(localStorage.getItem('priceTva')             !== null ? parseFloat(localStorage.getItem('priceTva')) : undefined);
    const [ priceTtc             ,setPriceTtc               ] = useState(localStorage.getItem('priceTtc')             !== null ? parseFloat(localStorage.getItem('priceTtc')) : undefined);    
    const [ price                ,setPrice                  ] = useState(localStorage.getItem('price')                !== null ? parseFloat(localStorage.getItem('price')) : undefined);
    const [ framesSelected       ,setFramesSelected         ] = useState(localStorage.getItem('framesSelected')       !== null ? parseInt(localStorage.getItem('framesSelected')) : undefined);
    const [ days                 ,setDays                   ] = useState(localStorage.getItem('days')                 !== null ? parseInt(localStorage.getItem('days')) : undefined);
    const [ slotFree             ,setSlotFree               ] = useState(localStorage.getItem('slotFree')             !== null ? CommonUtilities.stringToBoolean(localStorage.getItem('slotFree')) : undefined);
    const [ slotSelected         ,setSlotSelected           ] = useState(localStorage.getItem('slotSelected')         !== null ? parseInt(localStorage.getItem('slotSelected')) : undefined);
    const [ numberOfSpot         ,setNumberOfSpot           ] = useState(localStorage.getItem('numberOfSpot')         !== null ? parseInt(localStorage.getItem('numberOfSpot')) : undefined);
    const [ estimatedAvgContacts ,setEstimatedAvgContacts   ] = useState(localStorage.getItem('estimatedAvgContacts') !== null ? parseInt(localStorage.getItem('estimatedAvgContacts')) : undefined);
    const [ userEmail            ,setUserEmail              ] = useState(localStorage.getItem('user_email')           !== null ? localStorage.getItem('user_email') : '');
    const [ userPrefLang,        ,setUserPrefLang           ] = useState(localStorage.getItem('linguaUtente')         !== null ? localStorage.getItem('linguaUtente') : 'en');

    //di base carico glooh come vista
    const [ customUploadView     ,setCustomUploadView       ] = useState(true);
    const [ loadingUpload        ,setLoadingUpload          ] = useState(false);
    const [ panelFilename        ,setPanelFilename          ] = useState("");
    const [ mapFilename          ] = useState('');
    const [ customCreativeId     ,setCustomCreativeId       ] = useState("");
    const [ originalFileName     ,setOriginalFileName       ] = useState("");

    const [ openPreviewDialog    ,setOpenPreviewDialog      ] = useState(false);

    const [ videoUrl             ,setVideoUrl               ] = useState('');

    const history = useHistory();
    const auth = useContext(AuthContext);

    const {intl} = props;

    //stato per controllare l'apertura del dialog
    const [confirmBackDialog, setConfirmBackDialog] = useState(false);

    const translate = (label) =>  intl.formatMessage({id: label});

    useEffect(() => {
        setLoading(true);
        //console.log('gloohId', gloohId)
        //console.log('gloohPwd', gloohPwd)
        //se non è già definito si crea un nuovo id creatività e brief id prendendo da 2 SEQUENCE
        if(gloohCreativeId === ''){
            setGloohCreativeId(uuidv4());
            setGloohBriefId(uuidv4());
        }

        //communication with Glooh IFrame
        window.addEventListener("message", (event) => {
            if(event.data.type === 'creative_published'){
                gotoCampaignSummary();
            }
         }, false);

         window.addEventListener("message", (event) => {
            if(event.data.type === 'error'){
                promptGloohError();
            }
         }, false);

        //getGlooh();

    }, []);

    useEffect(() => {
        setLoading(false);
    }, [accessToken, setRefreshToken]);

    //useEffect parameters
    //gli stati che servono alle altre pagine sono salvati nel localStorage
    useEffect(() => {        
        localStorage.setItem('gloohCreativeId', gloohCreativeId);
        //console.log('gloohCreativeId', gloohCreativeId);
        
    }, [gloohCreativeId]);
    useEffect(() => {        
        localStorage.setItem('gloohBriefId', gloohBriefId);
        //console.log('gloohBriefId', gloohBriefId);
    }, [gloohBriefId]);    
    //end useEffect parameters

    /* const getGlooh = () => {

        //if(localStorage.getItem('glooh-access-token') === null){
            //get Glooh access token
            //axios.get()

            //console.log('glooh.value', config.GLOOH_VALUE)
            //console.log('glooh.url', config.GLOOH_URL)
            //console.log('glooh.urlMedia', config.GLOOH_MEDIA_URL)
            let path = "";
            if(config.GLOOH_VALUE === true){
                path = "/login";
            }else{
                path = "/custom/login";
            }
        
            //logger.info("glooh - login", { user, password });

            let payload = {
                user     : userEmail,
                password : gloohPwd
            };

            new Promise( (resolve, reject) => {
                const url = config.GLOOH_URL + path;
        
                const settings = {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }
        
                axios.post(url, payload, settings)
                .then(function (response) {
                    resolve(response);
                    setAccessToken(response.data.accessToken);
                    setRefreshToken(response.data.refreshToken)
                })
                .catch(function (error) {
                    NotificationManager.error('Glooh login error', 'Glooh error', 5000);
                    reject();
                });
            });
    }; */

    const updateMapPhoto = (mapPhoto, mapFilename, mapFilenameRemove = false) => {

        setLoadingUpload(true);
    
        const url = `${config.API_URL}/map-photo-set`;
    
        const localKCountry = localStorage.getItem('kCountry');
        const localKLevel = localStorage.getItem('kLevel');
        const localKItem = localStorage.getItem('kItem');
        const localCountryDetailLevel = localStorage.getItem('countryDetailLevel');
        
    
        const params = {
          kLevel: localKLevel,
          kItem: localKItem,
          mapPhoto,
          mapFilename,
          mapFilenameRemove: mapFilenameRemove ? 'Y' : 'N',
        };
    
        axios.post(
            url,
            null,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            },
        ).then((response) => {
    
          //loadTreeView(localKCountry, localCountryDetailLevel);
          setLoadingUpload(false);
    
          if(mapFilenameRemove){
            setPanelFilename("");
          }
          
        }, (error) => {
            if(error.response.status === 401){
                auth.signOut();
            }else{
                console.error(error.response);
            }
            setLoadingUpload(false);
        });
    
      }

    // *** dropzone ***

    const onDrop = useCallback(acceptedFiles => {
        if(acceptedFiles.length!==1){NotificationManager.error(
            <FormattedMessage id={'campaign.creative.upload.criteria'}/>, <FormattedMessage id={'campaign.creative.upload.invalid'}/>, 5000);
            return;
        }
        setLoadingUpload(true);
          acceptedFiles.forEach(async(file) => {
            if(file.size > 15728640){
                //console.log('file size is more than 15Mb');
                NotificationManager.error(<FormattedMessage id={'campaign.creative.upload.criteria'}/>, <FormattedMessage id={'campaign.creative.upload.invalid'}/>, 5000);
                return;
            }else if(file.type.split('/')[1] !== 'mp4'){
                //console.log('file type is not mp4');
                NotificationManager.error(<FormattedMessage id={'campaign.creative.upload.criteria'}/>, <FormattedMessage id={'campaign.creative.upload.invalid'}/>, 5000);
                return;
            }
            let response = '';
            let url = '';
            let params = {};
            let originalName = file.name;
            let fileName = uuidv4();
            //path per il file che deve essere caricato 
            let key = `video-uploads/${fileName}.mp4`;
            try{
              //upload del file nella cartella original
              /* url = `${config.API_URL}/upload-signed-url`;
              params = {
                key,
                type: file.type,
                file: file
              };
              response = await axios.post(url, null, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params  
                });

            if(response.status === 200){

                url = response.data.url;
                const options = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
                response = await axios.put(url, file, options); */
                /* if(response){
                  console.log('response post upload', response);
                  console.log('fine upload');
                } */
            /* } */

            /* 
                per lambda va su ec2 no
            params = {
                key,
                type: file.type,
                //file: file
            };
            url = `${config.API_URL}/upload-file`
            response = await axios.post(url, file, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`,
                    "Content-Type": "multipart/form-data"
                },
                params  
            }); */

            const form = new FormData();
				form.append('file', file);
				response = await fetch(
					`${config.API_URL}/upload-file?key=${key}`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${auth.sessionInfo?.accessToken}`
					},
					body: form
				});
				//
            const message = await response.json()/*  as string */;

            //lancio lambda del processing
            //url = `${config.API_URL}/process-uploads`;
            url = `https://ycdldm5qw8.execute-api.eu-west-3.amazonaws.com/process-uploads`
            params={
                fileName: fileName,
                kCountry: country
            }
            response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },
                params  
            });

              if(response.status === 200){
                NotificationManager.success(<FormattedMessage id={'campaign.creative.upload.success'}/>, <FormattedMessage id={'confirm.success.title'}/>, 5000);
              }/* else{
                console.log('oh no');
              } */
            }catch(error){
                console.log(error)
              //console.log('errori lambda',error);
              NotificationManager.error(<FormattedMessage id={'campaign.creative.upload.criteria'}/>, <FormattedMessage id={'campaign.creative.upload.invalid'}/>, 5000);
              setLoadingUpload(false);
              return;
            }
            //se tutto va a buon fine allora permetto all'utente di procedere
            setOriginalFileName(originalName);
            setCustomCreativeId(fileName);
            setLoadingUpload(false);
          });
    }, []);
    
    const {
        /* acceptedFiles, */
        getRootProps,
        getInputProps,
        open,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: {'video/mp4':['.mp4']},  multiple: false, maxFiles: 1, maxSize: 31457280});
    
    const dropZonestyle = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
      
    const removeFile = () => {
        updateMapPhoto("", "", true);
    }

    // *** end dropzone ***
    
    const promptGloohError = () =>{
        NotificationManager.error(<FormattedMessage id={'notificationManager.GloohError.text'}/>, <FormattedMessage id={'notificationManager.GloohError.title'}/>, 5000);
    };
    
    const gotoMyCampaigns = () => {
        history.push('/my-campaigns');
    }
    const countriesArray = config.COUNTRIES_LABELS;
    let urlCountry = countriesArray[country-1];

    const handleConfirmCloseBackDialog = () =>{
        setConfirmBackDialog(false);
    };

    const gotoLocationSelection = () => {
        history.push(`/${urlCountry}/location-selection`);
    };

    const gotoYourCampaign = () => {
        history.push(`/${urlCountry}/your-campaign/${kCampaign}`);
    };

    const gotoCampaignSummary = () => {
        history.push(`/${urlCountry}/campaign-summary/${kCampaign}`);
    };

    const switchView = (val) => {
        setCustomUploadView(val);
    };

    const gotoCampaignSummaryFromCustomView = () => {
        localStorage.setItem('gloohCreativeId', '');
        localStorage.setItem('gloohBriefId', '');
        localStorage.setItem('customCreativeId', customCreativeId);
        localStorage.setItem('gloohCreativeIdForPreview', '');
        gotoCampaignSummary();
    }

    const handleResetFileUpload = () => {
        setCustomCreativeId('');
        setOriginalFileName('');
        setVideoUrl('');
    };

    const handleClosePreviewDialog = () => {
        setOpenPreviewDialog(false);
    };

    const openPreview = async() => {
        if(videoUrl===''){
            setLoadingUpload(true);
            let response = '';
            let url = `${config.API_URL}/content`;
            let params = {
                contentUrl: `videos/portrait/${customCreativeId}.mp4`
            };
            try{
                response = await axios.get(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params  
                });
                if (response.status===200){
                    let url = response.data
                    //console.log('url', url);
                    setVideoUrl(url);
                }
            }catch(err){
                //console.log('err', err)
            }
            setLoadingUpload(false);
        }
        setOpenPreviewDialog(true);
    };

    return(
        <div className="campaign-creative">
        {loadingUpload &&(
        <div><CircleProgress/></div>)}
            <header className="heading">
            <div className="heading-content">
                <h1 className="h1">{campaignName}</h1>
                {country==='1' ? (<div className="breadcrumb margin-top--05">

                    <button className="breadcrumb--item completed" type="button" onClick={gotoYourCampaign}>
                        <span className='step-number'>1</span>
                        <FormattedMessage id={"breadcrumb.step1"}/>
                    </button>

                    <span className="next-step">
                        <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                    </span>

                    <button className="breadcrumb--item active default-curs" type="button">
                        <span className='step-number'>2</span>
                        <FormattedMessage id={"breadcrumb.step2"}/>
                    </button>

                    <span className="next-step">
                        <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                    </span>

                    <button className="breadcrumb--item disabled default-curs" type="button">
                        <span className='step-number'>3</span>
                        <FormattedMessage id={"breadcrumb.step3"}/>
                    </button>

                    <span className="next-step">
                        <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                    </span>

                    <button className="breadcrumb--item disabled default-curs" type="button">
                        <span className='step-number'>4</span>
                        <FormattedMessage id={"breadcrumb.step4"}/>
                    </button>
                </div>)
                :
                (<div className="breadcrumb margin-top--05">

                    <button className={`breadcrumb--item completed`} type="button" onClick={() => {setConfirmBackDialog(true)}}>
                        <span className='step-number'>1</span>
                        <FormattedMessage id={"breadcrumb.stepLocation"}/>
                    </button>

                    <span className="next-step">
                        <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                    </span>

                    <button className="breadcrumb--item completed" type="button" onClick={gotoYourCampaign}>
                        <span className='step-number'>2</span>
                        <FormattedMessage id={"breadcrumb.step1"}/>
                    </button>

                    <span className="next-step">
                        <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                    </span>

                    <button className="breadcrumb--item active default-curs" type="button">
                        <span className='step-number'>3</span>
                        <FormattedMessage id={"breadcrumb.step2"}/>
                    </button>

                    <span className="next-step">
                        <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                    </span>

                    <button className="breadcrumb--item disabled default-curs" type="button">
                        <span className='step-number'>4</span>
                        <FormattedMessage id={"breadcrumb.step3"}/>
                    </button>

                    <span className="next-step">
                        <NavigateNextIcon className="arrowNext"></NavigateNextIcon>
                    </span>

                    <button className="breadcrumb--item disabled default-curs" type="button">
                        <span className='step-number'>5</span>
                        <FormattedMessage id={"breadcrumb.step4"}/>
                    </button>
                </div>)}
                </div>
            </header>
            {/* <div className='switch-container'>
                <button className={`${customUploadView ? '' : 'highlighted'}`} onClick={()=>switchView(false)}><FormattedMessage id={"campaign.creative.template"}/></button>
                <button className={`${customUploadView ? 'highlighted' : ''}`} onClick={()=>switchView(true)}><FormattedMessage id={"campaign.creative.custom"}/></button>
            </div> */}
            {customUploadView 
            ?
            <section className="section-layout main-content">
            <div className='wrapper'>
                <div className='upload-section'>
                    <div className='info-box'>
                        <div><FormattedMessage id={"campaign.creative.specs"}/></div>
                        <ul className='list-padding'>
                            <li><FormattedMessage id={"campaign.creative.dimension.label"}/> : <FormattedMessage id={"campaign.creative.dimension.value"}/></li>
                            <li><FormattedMessage id={"campaign.creative.duration.label"}/> : {country==='1' || country==='3' ? '10':'6'} <FormattedMessage id={"campaign.creative.duration.value"}/></li>
                            <li><FormattedMessage id={"campaign.creative.format.label"}/> : <FormattedMessage id={"campaign.creative.format.value"}/></li>
                            <li><FormattedMessage id={"campaign.creative.size.label"}/> : <FormattedMessage id={"campaign.creative.size.value"}/></li>
                        </ul>
                        <button className='button--primary-large' type="button" onClick={open} disabled={customCreativeId!==''}><FormattedMessage id={"campaign.creative.addFile"}/> <Image24/></button>
                        <div className="input-container input-box">
                            <input className='input' placeholder='file' readOnly value={customCreativeId ? originalFileName : ''}/>
                            {originalFileName && (<IconButton size='medium'>
                                <Close32 onClick={handleResetFileUpload}/>
                            </IconButton>)}
                        </div>
                    </div>
                    {customCreativeId!==''
                    ?
                    <div className='thumb-preview' onClick={openPreview} style={{backgroundImage: `url('${config.CONTENT_DISTRIBUTION_URL_ENV}/thumbs/${customCreativeId}.png')`}}></div>
                    :
                    (<div className="dropBox" {...getRootProps({style: dropZonestyle})}>
                        <input {...getInputProps()} />
                        {/* {mapFilename !== "" && (
                            <p>{mapFilename} {mapInherit ? "(inherit)" : ""}</p>
                        )} */}
                        {mapFilename === "" && (
                            <>
                                <p style={{textAlign: "center"}}><FormattedMessage id={"campaign.creative.dropBox.first"}/></p>
                                <p style={{textAlign: "center"}}><FormattedMessage id={"campaign.creative.dropBox.second"}/></p>
                            </>
                        )}
                    </div>)}
                </div>    
                {/* {mapFilename !== "" && !mapInherit && (
                    <p className="remove-file"><Tooltip title="Delete Photo"><CloseIcon onClick={removeFile} /></Tooltip></p>
                )} */}
                {/* <div className="separator" style={{marginTop:'25px'}}></div> */}
                <div className="button-line--wrapper force-end">
                    <button className="button--primary-large" type="button" disabled={!customCreativeId} onClick={gotoCampaignSummaryFromCustomView}><FormattedMessage id="campaigns.next-step"/></button>    
                </div>
                </div>
            </section>
            :
            <section className="section-layout">
                <div className="wrapper">
                {/*     già scritto nell'iFrame di glooh
                <h4 className="h4 margin"><FormattedMessage id={"campaign.creative.title"}/></h4> */}
                {/* <iframe 
                        title='glooh-creativity'
                        id="glooh-iframe"
                        src={`${config.GLOOH_MEDIA_URL}?refresh_token=${refreshToken}&access_token=${accessToken}&campaign_brief_id=${gloohBriefId}&creative_id=${gloohCreativeId}&tag=${country === '1' ? 'golive_fr' : 'golive_be_'+userPrefLang}`}
                        width="100%"
                        height="1000px"
                        frameBorder="0"
                /> */}
                </div>
            </section>}
            <footer className="section-layout text-center">
                {/* <p className="paragraph"><FormattedMessage id="assistance.message"/><a href={`mailto:${translate(`assistance.email.${country}`)}`}><FormattedMessage id={`assistance.email.${country}`}/></a></p> */}
                <LegalLinks country={country}/>
            </footer>

            <Dialog onClose={handleClosePreviewDialog} open={openPreviewDialog}>
                <DialogTitle>
                    <div>
                        <h4 className="h4 dialog-prev-title">
                            {originalFileName}
                        </h4>
                        <video width="360" height="640" controls autoplay="true">
                            <source src={videoUrl} type="video/mp4"/>
                        </video>
                    </div>
                </DialogTitle>
                <DialogActions className='pointto box-pad-minus'>
                    <button className="button--quaternary-large" style={{padding:'0.6rem 1.4rem', color:'black'}} onClick={handleClosePreviewDialog}>
                        <FormattedMessage id="modal.confirm.back.cancel"/>
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={handleConfirmCloseBackDialog} open={confirmBackDialog}>
                <DialogTitle>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className='item tag' style={{backgroundColor:'#FFEAAB', color:'#EEB302', borderRadius:'50%', width:'4rem', height:'4rem'}}>
                            <WarningAmberIcon/>
                        </span>&nbsp;
                        <h4 className="h4" style={{fontWeight: '400'}}>
                            <FormattedMessage id="modal.confirm.back.text"/>
                        </h4>
                    </div>
                </DialogTitle>
                <DialogActions className='pointto box-pad-minus'>
                    <button className="button--secondary-large" style={{padding:'0.6rem 1.4rem'}} onClick={gotoLocationSelection}>
                        <FormattedMessage id="modal.confirm.back.confirm"/>
                    </button>
                    <button className="button--quaternary-large" style={{padding:'0.6rem 1.4rem', color:'black'}} onClick={handleConfirmCloseBackDialog}>
                        <FormattedMessage id="modal.confirm.back.cancel"/>
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default injectIntl(CampaignCreative);