import React, { useState, useEffect, useContext } from 'react';
import "./CampaignFinal.css";
import { AuthContext } from '../../contexts/authContext'
import axios from 'axios';
import CommonUtilities from "../../util/CommonUtilities";
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";
import LegalLinks from '../../components/LegalLinks';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import CircleProgress from '../../util/CircleProgress';
import { NotificationManager } from 'react-notifications';
import { Language } from '../../App';
import { Enterprise24, CalendarHeatMap32, Screen32, Events32, TimePlot32, PlayOutline32, FitToWidth32, VideoChat32, Calendar32, Train32, Store32 }   from '@carbon/icons-react';
import DialogActions                                from '@mui/material/DialogActions';
import Dialog                                       from '@mui/material/Dialog';
import DialogTitle                                  from '@mui/material/DialogTitle';

const CampaignFinal = (props) =>{
    //state params
    const [country, setCountry] = useState(localStorage.getItem('country'));
    const [locationName, setLocationName] = useState(localStorage.getItem('locationName'));
    //vengono inizializzati dal localStorage se !== null (in quanto si potrebbe arrivare da altre pagine con dei parametri) o con un valore di default
    const [kCampaign, setKCampaign] = useState(undefined);
    const [statusCod , setStatusCod] = useState(undefined);
    const [campaignName, setCampaignName] = useState(undefined);
    const [floor, setFloor] = useState(undefined);
    const [giant, setGiant] = useState(undefined);
    const [allFrames, setAllFrames] = useState(undefined);
    const [daypart, setDaypart] = useState(undefined);
    const [beginDate, setBeginDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [priceMedia, setPriceMedia] = useState(undefined);
    const [priceAdv, setPriceAdv] = useState(undefined); 
    const [priceTva, setPriceTva] = useState(undefined);
    const [priceTtc, setPriceTtc] = useState(undefined);    
    const [price, setPrice] = useState(undefined);
    const [framesSelected, setFramesSelected] = useState(undefined);
    const [days, setDays] = useState(undefined);
    const [slotFree, setSlotFree] = useState(undefined);
    const [slotSelected, setSlotSelected] = useState(undefined);    
    const [numberOfSpot, setNumberOfSpot] = useState(undefined);
    const [estimatedAvgContacts, setEstimatedAvgContacts] = useState(undefined);
    const [gloohCreativeId, setGloohCreativeId] = useState(undefined);    
    const [gloohBriefId, setGloohBriefId] = useState(undefined);    
    const [gloohRendered, setGloohRendered] = useState(undefined);
    const [customCreativeId, setCustomCreativeId] = useState(undefined);
    const [daypartList, setDaypartList] = useState([]);
    const [framesLevels, setFramesLevels] = useState([]);
    const [loading, setLoading] = useState(true);
    const auth = useContext(AuthContext);
    const history = useHistory();
    const {language, setLanguage} = useContext(Language)
    const [showAudience, setShowAudience] = useState(false);
    const [signedUrl, setSignedUrl] = useState('');
    const [panelsSelected, setPanelsSelected] = useState([]);
    const [panelsList, setPanelsList] = useState('');

    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [loadingUpload,setLoadingUpload] = useState(false);

    const [promoCodeDiscount, setPromoCodeDiscount] = useState(undefined);
    const [promoCode, setPromoCode] = useState(undefined);
    const [promoCodeDiscountPerc, setPromoCodeDiscountPerc] = useState(undefined);
    const [promoCodeDiscountType, setPromoCodeDiscountType] = useState(undefined);

    const [originalPrice, setOriginalPrice] = useState(undefined);
    const [originalTvaPrice, setOriginalTvaPrice] = useState(undefined);
    const [originalTtcPrice, setOriginalTtcPrice] = useState(undefined);

    const {intl} = props;

    const translate = (label) =>  intl.formatMessage({id: label});

    const [isCustomCreative, setIsCustomCreative] = useState(false);

    useEffect(() => {
        async function init(){
            //remove parameters from localStorage (capire se va bene farlo qua)
            localStorage.removeItem('kCampaign');
            localStorage.removeItem('campaignName');
            localStorage.removeItem('floor');
            localStorage.removeItem('giant');
            localStorage.removeItem('allFrames');
            localStorage.removeItem('daypart');
            localStorage.removeItem('beginDate');
            localStorage.removeItem('endDate');
            localStorage.removeItem('gloohCreativeId');
            localStorage.removeItem('gloohBriefId');
            localStorage.removeItem('gloohRendered');
            localStorage.removeItem('priceMedia');
            localStorage.removeItem('priceAdv');
            localStorage.removeItem('priceTva');
            localStorage.removeItem('priceTtc');
            localStorage.removeItem('price');
            localStorage.removeItem('framesSelected');
            localStorage.removeItem('days');
            localStorage.removeItem('numberOfSpot');
            localStorage.removeItem('slotFree');
            localStorage.removeItem('slotSelected');
            localStorage.removeItem('estimatedAvgContacts');
            localStorage.removeItem('userBasket');
            localStorage.removeItem('campaignRejected');
            localStorage.removeItem('panelsList');
            localStorage.removeItem('customCreativeId');
            localStorage.removeItem('gloohBriefIdForPreview');
            localStorage.removeItem('gloohCreativeIdForPreview');
            localStorage.removeItem('promoCodeDiscount');
            localStorage.removeItem('originalPrice');
            localStorage.removeItem('originalPriceTva');
            localStorage.removeItem('originalPriceTtc');
            
            const stringParameters = new URLSearchParams( '?' + window.location.href.split('?')[1]);
            //console.log('stringParameters', stringParameters)
            //console.log('stringParameters', stringParameters.has('stripe-id'))
            //console.log('stringParameters', stringParameters.has('client-secret'))
            //la payed viene solo lanciata se si ritorna dal pagamento di stripe
            if(stringParameters.has('stripe-id')){
                const url = `${config.API_URL}/campaign-payed`;
                
                const params = {
                    kCampaign: props.match.params.id,
                    stripeId: stringParameters.get('stripe-id'),
                };
                //console.log('params campaign payed', params);
                let responsePayed = null;
        
                try{
                    responsePayed = await axios.post(
                        url,
                        params,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                            }  
                        }
                    );
                }catch(error){
                    if (!error?.response){
                        NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                        history.push('/connection-error');
                        return;
                    }else if(error?.response?.status === 401){ //status 401: Unauthorized
                        NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                        setLoading(true);
                        auth.signOut();
                        history.push('/signin');
                    }else if(error?.response?.status ===500){
                        history.push('/connection-error');
                    }else{
                        history.push('/connection-error');
                    }
                }
            }

            setKCampaign(props.match.params.id);
            await getCampaign(props.match.params.id);

            let url = `${config.API_URL}/dayparts`;

            let params = {
                kCountry: country,
            }

            await axios.get(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },params  
                }
            ).then((response) => {
                //console.log(response.data)
                setDaypartList(response.data);
            }, (error) => {
                if (!error?.response){
                    NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 0);
                    history.push('/connection-error');
                    return;
                }else if(error?.response?.status === 401){ //status 401: Unauthorized
                    NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                    auth.signOut();
                    history.push('/signin');
                }else if(error?.response?.status ===500){
                    history.push('/connection-error');
                }else{
                    history.push('/connection-error');
                }
            });
            setLoading(false);
        }
        init();
    }, []);

    useEffect(() => {        
        localStorage.setItem('kCampaign', kCampaign);
    }, [kCampaign]);

    useEffect(() => {
        if(beginDate !== undefined && endDate !== undefined){
            if(beginDate.startOf('day').isAfter(endDate.startOf('day'))){
                setEndDate(beginDate.clone());
            }

            const duration = moment.duration(endDate.diff(beginDate));
            const days = parseInt(duration.asDays()) + 1;
            setDays(days);
        }
    }, [beginDate, endDate]);

    const getCampaign = async(kCampaign) => {
        setLoading(true);

        //si carica una campagna già esistente
        const params = {
            kCampaign,
            savedData: 'Y'
        };
        const url = `${config.API_URL}/campaign`;

        await axios.get(
            url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                },params  
            }
        ).then((response) => {
            //console.log(response.data)
            const data = response.data;
            //let matchPackage = JSON.parse(data.FRAMES_LEVELS).find(framesLevels => framesLevels.kLevel.toString() === data.FRAMES_FLOOR);
            setCampaignName(data.DCAMPAIGN);
            setStatusCod(data.STATUS_COD);
            setBeginDate(moment(data.BEGIN_DATE));
            setEndDate(moment(data.END_DATE));
            setFloor(data.FRAMES_FLOOR);
            setGiant(data.FRAMES_GIANT === 'Y' ? true : false);
            setAllFrames(data.FRAMES_ALL === 'Y' ? true : false);
            setDaypart(!data.KDAYPART ? 0 : data.KDAYPART);
            setNumberOfSpot(data.NUMBER_OF_SPOT);   
            setEstimatedAvgContacts(data.ESTIMATED_AVG_CONTACTS);
            setPrice(data.TOTAL_COST);
            setPriceMedia(data.MEDIA_COST);
            setPriceAdv(data.ADV_COST);
            setPriceTva(data.TVA_COST);
            setPriceTtc(data.TTC_COST);                  
            //commentato per il problema con pSavedData pari ad 'Y' nella getCampaign(s)
            //setFramesSelected(data.FRAMES_SELECTED);
            setGloohCreativeId(data.GLOOH_CREATIVE_ID);
            setGloohBriefId(data.GLOOH_BRIEF_ID);
            setGloohRendered(data.GLOOH_RENDERED === 'Y' ? true : false);
            setCustomCreativeId(data.CUSTOM_CREATIVE_ID);
            setShowAudience(data.SHOW_AUDIENCE === 'Y' ? true : false);
            setPanelsSelected(JSON.parse(data.PANELS_SELECTED));
            setPanelsList(data.PANELS_LIST);
            setPromoCodeDiscount(data.PROMO_CODE_DISCOUNT);
            setPromoCode(data.PROMO_CODE);
            setPromoCodeDiscountPerc(data.DISCOUNT);
            setPromoCodeDiscountType(data.DISCOUNT_TYPE);
            setOriginalPrice(data.TOTAL_COST_SAVE);
            setOriginalTvaPrice(data.TVA_COST_SAVE);
            setOriginalTtcPrice(data.TTC_COST_SAVE);
            if(country==='1'){
                setFramesLevels(JSON.parse(data.FRAMES_LEVELS).find(framesLevels => framesLevels.kLevel.toString() === data.FRAMES_FLOOR));
                setFramesSelected(JSON.parse(data.PANELS_SELECTED).length);
            }else{
                setPanelsList(data.PANELS_LIST);
                setFramesSelected(data.PANELS_LIST.split(',').length);
            }
            if(data.CUSTOM_CREATIVE_ID){
                setIsCustomCreative(true);
            }
        }, (error) => {
            if(error.response.status === 401){ //status 401: Unauthorized
                NotificationManager.error(<FormattedMessage id={'notificationManager.connectionError.401.text'}/>, <FormattedMessage id={'notificationManager.connectionError.401.title'}/>, 5000);
                setLoading(true);
                auth.signOut();
                history.push('/signin');
            }else if(error.response.status ===500){
                NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                history.push('/connection-error');
            }else{
                NotificationManager.error( <FormattedMessage id={'notificationManager.connectionError.text'}/>, <FormattedMessage id={'error'}/>, 5000);
                history.push('/connection-error');
            }
        });
    }

    const openPreview = async() => {
        if(videoUrl===''){
            setLoadingUpload(true);
            let response = '';
            let url = `${config.API_URL}/content`;
            let params = {
                contentUrl: `videos/portrait/${gloohCreativeId ? gloohCreativeId : customCreativeId}.mp4`
            };
            try{
                response = await axios.get(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                    },
                    params  
                });
                if (response.status===200){
                    let url = response.data
                    //console.log('url', url);
                    setVideoUrl(url);
                }
            }catch(err){
                //console.log('err', err)
            }
            setLoadingUpload(false);
        }
        setOpenPreviewDialog(true);
    };

    const daypartLabel = () =>{
        //console.log('daypartList',daypartList)
        //console.log('daypart',daypart)
        
        let fasciaOraria = daypartList.find(element => element.KDAYPART===daypart)||{};
        return( <FormattedMessage id={'your-campaigns.'+(fasciaOraria.KDAYPART===daypart ? fasciaOraria.DDAYPART : 'daypart.full')}/>)
    }

    const gotoMyCampaigns = () => {
        history.push('/my-campaigns');
    } 

    const createPanelsSelectionInfo = () => {
        let arrayPanelsList = country === '1' ? panelsSelected.map(faccia => faccia.kFace+'') : panelsList.split(',');
        return panelsSelected.filter(pannello => arrayPanelsList.includes(pannello.kFace+'')).map( selectedPanel => {

                let titleContent =
`${translate('campaign.summary.panel.address')}: ${selectedPanel.address}
${translate('campaign.summary.panel.dimensions')}: ${selectedPanel.dimensions}
${translate('campaign.summary.panel.resolution')}: ${selectedPanel.resolution}`
            return ( <p className='margin-bottom--00' key={selectedPanel.kFace} title={titleContent} style={{cursor:'default'}}>{selectedPanel.digitalId || 'N.A.'}</p>)    
            }
        )
    };

    const handleClosePreviewDialog = () => {
        setOpenPreviewDialog(false);
    };

    if(loading){
        return(<CircleProgress/>);
    }else{
        return(
            <div className="campaign-final">
            {loadingUpload &&(
            <div><CircleProgress/></div>)}
                <header className="heading">
                    <div className="heading-content">
                        <h1 className="h1">{campaignName}</h1>
                    </div>
                </header>

                <section className="section-layout">
                <div className="grid wrapper">
                <h4 className="h4 margin"><FormattedMessage id={"campaign.summary.title"}/></h4>
                    <div className="grid">
                        <div className="col-desk-16 col-mob-4">
                            <article className="card">
                                <div className="card__container">
                                        <div className="card__container--header">
                                            <h4 className='h4'><FormattedMessage id={"breadcrumb.step3"}/></h4>
                                                {
                                                statusCod === 1
                                                ?
                                                <span className="tag warning item" data-cy="warningTag" role="button" tabIndex="0"><FormattedMessage id="my-campaigns.created"/></span>
                                                :
                                                statusCod === 2
                                                ?
                                                <span className="tag warning item" data-cy="warningTag" role="button" tabIndex="0"><FormattedMessage id="my-campaigns.wait-payment"/></span>
                                                :
                                                statusCod === 3
                                                ?
                                                <span className="tag warning item" data-cy="warningTag" role="button" tabIndex="0"><FormattedMessage id="my-campaigns.wait-approval"/></span>
                                                :
                                                statusCod === 4
                                                ?
                                                <span className="tag success item" data-cy="successTag" role="button" tabIndex="0"><FormattedMessage id="my-campaigns.approved"/></span>
                                                :
                                                <span></span>
                                                }             
                                             
                                        </div>
                                        <div className="card__container--content">
                                            <div className="layout-flex">
                                                {country==='1'
                                                ?
                                                <Store32 aria-label="Add" className="icon"/>
                                                :
                                                <Train32 aria-label="Add" className="icon" />}
                                                <h3 className="h3 margin-left--02">{locationName}</h3>
                                            </div>
                                            <div className="grid margin-top--06">  

                                                <div className="col-desk-12 col-mob-4">
                                                    <div className="info-list margin-bottom--05">
                                                        <Calendar32 aria-label="Add" className="icon" />
                                                        <div>
                                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.date.begin"}/></p>
                                                            <p className="margin-bottom--00">{beginDate.format('DD/MM/YYYY').toString()}</p>
                                                            <p className="margin-bottom--00">{endDate.format('DD/MM/YYYY').toString()}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-desk-12 col-mob-4">
                                                    <div className="info-list margin-bottom--05">
                                                        <VideoChat32 aria-label="Add" className="icon" />
                                                        <div>
                                                        <p className="paragraph margin-bottom--00">
                                                            <FormattedMessage id={"campaign.summary.details.creativity"}/></p>
                                                            {(gloohRendered || customCreativeId) ? 
                                                                <img className="slot-thumb pointer-curs" onClick={openPreview} src={`${config.CONTENT_DISTRIBUTION_URL_ENV}/thumbs/${gloohCreativeId ? gloohCreativeId : customCreativeId}.png`} alt='creativity'/>
                                                                :
                                                                <p className="margin-bottom--00"><FormattedMessage id={"campaign.summary.details.creativityStatus"}/></p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-desk-12 col-mob-4">
                                                    <div className="info-list margin-bottom--05">
                                                        <Screen32 aria-label="Add" className="icon" />
                                                        <div>
                                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.screens"}/></p>
                                                            <p className="margin-bottom--00">{framesSelected}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-desk-12 col-mob-4">
                                                    <div className="info-list margin-bottom--05">
                                                        <CalendarHeatMap32 aria-label="Add" className="icon" />
                                                        <div>
                                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.days"}/></p>
                                                            <p className="margin-bottom--00">{days}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-desk-12 col-mob-4">
                                                    <div className="info-list margin-bottom--05">
                                                        <TimePlot32 aria-label="Add" className="icon" />
                                                        <div>
                                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.frequence"}/></p>
                                                            <p className="margin-bottom--00">{country==='1'?<FormattedMessage id={"campaign.summary.distribution.frequence/time.fr"}/>:<FormattedMessage id={"campaign.summary.distribution.frequence/time.be"}/>}</p>  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-desk-12 col-mob-4">
                                                    <div className="info-list margin-bottom--05">
                                                        <PlayOutline32 aria-label="Add" className="icon" />
                                                        <div>
                                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.spot"}/></p>
                                                            <p className="margin-bottom--00">{CommonUtilities.formatPositiveIntNum(numberOfSpot)}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-desk-12 col-mob-4">
                                                    <div className="info-list margin-bottom--05">
                                                        <FitToWidth32 aria-label="Add" className="icon" />
                                                        <div>
                                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.details.perimeter"}/></p>
                                                            <p className="margin-bottom--00">
                                                                {country==='1' && <FormattedMessage id={`your-campaigns.${framesLevels.dLevel}`}/>}
                                                                {country==='2' && framesLevels.kLevel!==0 && panelsList.length===0 && (<>&nbsp;&nbsp;•&nbsp;&nbsp;<FormattedMessage id={`your-campaigns.${framesLevels.groupLabel}`}/></>)}
                                                                {country==='1' && (giant
                                                                ?<><br />
                                                                <FormattedMessage id={"campaign.summary.details.perimeter.giants.yes"}/></>
                                                                :<><br />
                                                                <FormattedMessage id={"campaign.summary.details.perimeter.giants.no"}/></>
                                                                )}
                                                            </p>
                                                            { country==='2'&& createPanelsSelectionInfo()}
                                                            <p className="margin-bottom--00">
                                                                {daypartLabel()}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-desk-12 col-mob-4">
                                                    {showAudience ? <>
                                                    <div className="info-list margin-bottom--05">
                                                        <Events32 aria-label="Add" className="icon" />
                                                        <div>
                                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.contacts"}/></p>
                                                            <p className="margin-bottom--00">{CommonUtilities.formatPositiveIntNum(estimatedAvgContacts)}</p>
                                                        </div>
                                                    </div>
                                                    </> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </article>
                        </div>

                        <div className="col-desk-8 col-mob-4">
                            <article className="card">
                                <div className="card__container">
                                    <div className="card__container--header">
                                        <h4 className='h4'><FormattedMessage id={'your-campaigns.resume.title'}/></h4>
                                    </div>
                                    <div className="card__container--content">

                                        {country==='1'&&(<>
                                            <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.campaignCost"}/></p>
                                            {CommonUtilities.formatNumber(priceMedia, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
                                        </div>

                                        <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.advertiseCost"}/></p>
                                            {CommonUtilities.formatNumber(priceAdv, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
                                        </div>

                                        <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.creationCost"}/></p>
                                            <FormattedMessage id={"campaign.summary.distribution.creationCost.free"}/>
                                        </div></>)}

                                        <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.priceNet"}/></p>
                                            {CommonUtilities.formatNumber(!promoCodeDiscount ? price : originalPrice, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
                                        </div>

                                        {!promoCodeDiscount
                                            ?
                                            ''
                                            :
                                            <div className="layout-spacebetween margin-bottom--04">
                                                <p className="paragraph margin-bottom--00 green-txt"><FormattedMessage id={"campaign.summary.promoCode.first"}/> {promoCode} {promoCodeDiscountType===1&&(<><FormattedMessage id={"campaign.summary.promoCode.second"}/> {`(${promoCodeDiscountPerc}%)`}</>)}</p>
                                                <span className='green-txt'>{CommonUtilities.formatNumber(promoCodeDiscount, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</span>
                                            </div>
                                        }

                                        <div className="layout-spacebetween margin-bottom--04">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.tax"}/></p>
                                        {CommonUtilities.formatNumber(priceTva, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}
                                        </div>   

                                        <span className="separator layout-spacebetween margin-bottom--04"></span>

                                        <div className="layout-spacebetween">
                                            <p className="paragraph margin-bottom--00"><FormattedMessage id={"campaign.summary.distribution.totalPrice"}/></p>
                                            <span className="highlight">{CommonUtilities.formatNumber(priceTtc, language, {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})}</span>
                                        </div>   

                                    </div>
                            </div>
                            </article>
                        </div>
                    </div>
                    </div>
                </section>
                <footer className="section-layout text-center">
                        {/* <p className="paragraph"><FormattedMessage id="assistance.message"/><a href={`mailto:${translate(`assistance.email.${country}`)}`}><FormattedMessage id={`assistance.email.${country}`}/></a></p> */}
                       <LegalLinks country={country}/>
                </footer>

                <Dialog onClose={handleClosePreviewDialog} open={openPreviewDialog}>
                    <DialogTitle>
                        <div>
                            <video width="360" height="640" controls autoplay="true">
                                <source src={videoUrl} type="video/mp4"/>
                            </video>
                        </div>
                    </DialogTitle>
                    <DialogActions className='pointto box-pad-minus'>
                        <button className="button--quaternary-large" style={{padding:'0.6rem 1.4rem', color:'black'}} onClick={handleClosePreviewDialog}>
                            <FormattedMessage id="modal.confirm.back.cancel"/>
                        </button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

export default injectIntl(CampaignFinal);